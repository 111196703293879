import React from 'react';
import Layout from '../components/layout';
import { Contact } from '../components';

const Kapcsolat = () => {
  return (
    <Layout
      seo={{
        title: 'Kapcsolat',
        description:
          'Sikerélményt viszünk a fiatalok életébe szociális helyzettől függetlenül.'
      }}
    >
      <Contact />
    </Layout>
  );
};

export default Kapcsolat;
